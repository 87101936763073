<template>
    <div class="tab-container">
        <div class="head">
            <div @click="onTabChange(index)" v-for="(item, index) in list"
                :class="['tab-head-item', { 'active-tab-head': index === activeIndex }]" :key="index">
                <span class="tab-text">{{ baseTitle + (index + 1) }}</span>
                <img src="@/assets/images/adTemplate/delete.svg" @click.stop="closeTab(index)"
                    v-if="index !== 0 && activeIndex !== index" style="margin-left: 4px;">
                <img src="@/assets/images/adTemplate/delete-active.svg" @click.stop="closeTab(index)"
                    v-if="index !== 0 && activeIndex === index" style="margin-left: 4px;">
            </div>
            <div class="more" @click="addTabs()">
                <img src="@/assets/images/adTemplate/plus.svg" alt="">
            </div>
        </div>
        <div class="content">
            <div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, toRefs, onMounted } from 'vue'

const activeIndex = ref(0)
const emit = defineEmits(['change', 'delete', 'add'])
const props = defineProps({
    list: {//素材列表
        type: Array,
        default: () => []
    },
    baseTitle: {//tab的基础标题
        type: String,
        default: '素材'
    },
    maxTabs: {//最多可添加tab的最大数量
        type: Number,
        default: 5
    }
})
const { baseTitle, maxTabs, list } = toRefs(props)

//tab切换
const onTabChange = (index) => {
    activeIndex.value = index
    emit('change', index)
}
//tab删除
const closeTab = (index) => {
    if (activeIndex.value !== 0 && activeIndex.value >= index) activeIndex.value -= 1
    emit('delete', index)
}

//添tab
const addTabs = () => {
    const length = list.value.length
    if (length === maxTabs.value) return
    emit('add')
}
onMounted(() => {
    addTabs()
})

defineExpose({
    onTabChange
})
</script>

<style lang="less" scoped>
.tab-container {
    margin-bottom: 16px;

    .content {
        min-height: 200px;
        border: 1px solid #2B82FF;
        background-color: #F8F8FA;
        padding: 20px 10px;
        box-sizing: border-box;
    }

    .head {
        display: flex;
        height: 40px;
        cursor: pointer;

        .active-tab-head {
            border-top: 1px solid #2B82FF !important;
            border-left: 1px solid #2B82FF !important;
            border-right: 1px solid #2B82FF !important;
            background-color: #F8F8FA !important;
            position: relative;

            &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 15px;
                z-index: 999;
                background-color: #F8F8FA !important;
                bottom: 0;
                transform: translateY(50%);
            }

            .tab-text {
                font-size: 14px;
                font-family: PingFang SC-Regular;
                font-weight: 400;
                color: #2B82FF !important;
            }
        }

        .tab-head-item {
            padding: 0 10px;
            min-width: 70px;
            border: 1px solid #DCDFE6;
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            .tab-text {
                font-size: 14px;
                font-family: PingFang SC-Regular;
                font-weight: 400;
                color: #8C8C8C;
            }
        }

        .more {
            border: 1px solid #DCDFE6;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>