<template>
    <el-drawer v-model="isDrawerShow" :show-close="false" @close="$emit('close')" size="800px"
        custom-class="template-detail-drawer" :close-on-click-modal="false" ref="drawerRef">
        <template #title>
            <div class="top-container">
                <span>{{ props.name }}广告策略模板编辑</span>
                <img src="@/assets/images/adTemplate/close-icon.svg" alt="close" @click="isDrawerShow = false" />
            </div>
        </template>
        <div class="detail-container">
            <div class="detail-steps">
                <img src="@/assets/images/adTemplate/one-step.svg" alt="" v-if="currentStep === 0" />
                <img src="@/assets/images/adTemplate/one-step-success.svg" alt="" v-else />
                <span :class="{ 'active-step': currentStep === 0 }">模板信息</span>
                <div></div>
                <img src="@/assets/images/adTemplate/two-step.svg" alt="" v-if="currentStep === 0" />
                <img src="@/assets/images/adTemplate/two-step-success.svg" alt="" v-else />
                <span :class="{ 'active-step': currentStep === 1 }">创建预览</span>
            </div>
            <div class="detail-form">
                <el-form :rules="rules" ref="ruleFormRef" :model="ruleForm" v-show="currentStep === 0">
                    <div class="divider">账号授权</div>
                    <el-form-item label="授权平台:" required>
                        <el-button size="small" plain class="login-button" @click="openLoginDialog"
                            v-if="!ruleForm.fb_user_id">授权Facebook用户</el-button>
                        <div v-else class="login-user">
                            <img :src="ruleForm.fb_avatar" alt="avatar" />
                            <span>{{ ruleForm.fb_name }}</span>
                            <span class="help" @click="openLoginDialog" style="margin-left: 0">切换Facebook授权</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="广告账户:" v-if="ruleForm.fb_user_id">
                        <el-button size="small" type="primary" class="unlogin-button"
                            @click="changeAccount">选择广告账户</el-button>
                    </el-form-item>
                    <el-form-item label="已选账户:" v-if="ruleForm.fb_user_id && ruleForm.account_id">
                        <span>{{ ruleForm.account_id || '请选择广告账户' }}</span>
                    </el-form-item>
                    <el-form-item label="公共主页:" prop="page_id" v-if="ruleForm.fb_user_id && ruleForm.account_id">
                        <el-select v-model="ruleForm.page_id" placeholder="请选择公共主页" style="width: 270px" size="small">
                            <el-option v-for="item in options.page" :key="item.id" :label="item.name"
                                :value="item.id" />
                        </el-select>
                        <a class="help" href="https://yinocloud.yinolink.com/help/1005?type=1" target="_blank">无法选取？</a>
                    </el-form-item>
                    <el-form-item label="像素Pixel:" prop="pixel_id" v-if="ruleForm.fb_user_id && ruleForm.account_id">
                        <el-select v-model="ruleForm.pixel_id" placeholder="请选择像素Pixel" style="width: 270px"
                            size="small">
                            <el-option v-for="item in options.pixel" :key="item.id" :label="item.name"
                                :value="item.id" />
                        </el-select>
                        <a class="help" href="https://yinocloud.yinolink.com/help/1123?type=1" target="_blank">无法选取？</a>
                    </el-form-item>
                    <div class="divider">{{ ruleForm.type === 1 || ruleForm.type === 3 ? '广告系列' : '进阶赋能型智能购物广告系列' }}
                        <span v-show="ruleForm.type === 2 && audienceSavable" @click="resetAudience">恢复默认</span>
                    </div>
                    <el-form-item label="广告系列名称:" prop="campaign_name" style="margin-bottom: 8px;">
                        <el-input size="small" clearable v-model="ruleForm.campaign_name"
                            v-if="ruleForm.type === 1 || ruleForm.type === 3" :validate-event="false" />
                        <el-input size="small" clearable v-model="ruleForm.campaign_name" v-else
                            @input="ruleForm.adsets_name = ruleForm.campaign_name" :validate-event="false" />
                    </el-form-item>
                    <el-form-item label="广告目标:" style="margin-bottom: 0px;">
                        <span>{{ ruleForm.smart_promotion_type }}</span>
                    </el-form-item>
                    <el-form-item label="赋能型广告预算优化:" v-if="ruleForm.type === 1 || ruleForm.type === 3"
                        style="margin-bottom: 0px;">
                        <span>{{ ruleForm.ad_budget_improve }}</span>
                    </el-form-item>
                    <el-form-item label="自动规则:" style="margin-bottom: 0;">
                        <vxe-switch v-model="ruleForm.rule_switch" size="mini" open-label="打开" close-label="关闭"
                            @change="clearRule"></vxe-switch>
                    </el-form-item>
                    <div v-if="ruleForm.rule_switch">
                        <!-- <el-form-item label="应用层级:" style="margin-bottom: 0;"> -->
                        <!-- <el-radio-group v-model="ruleForm.rule_level"> -->
                        <!-- <el-radio :label="1">广告系列</el-radio> -->
                        <!-- <el-radio :label="2">广告组</el-radio> -->
                        <!-- <el-radio :label="3">广告</el-radio> -->
                        <!-- </el-radio-group> -->
                        <!-- </el-form-item> -->
                        <el-form-item label="选择规则:">
                            <el-select v-model="ruleForm.rule" placeholder="请选择自动化规则" size="small" style="width: 100%;"
                                filterable popper-class="ad-template-rule-select">
                                <div class="add-rule" @click="goCustomRule()">
                                    <i class="iconfont icon-xinjian" />
                                    <span>新建规则</span>
                                </div>
                                <el-option v-for="item in options.rule" :key="item.id" :label="item.name"
                                    :value="item.id">
                                    <el-row type="flex" justify="space-between" align="middle">
                                        <span>{{ item.name }}</span>
                                        <el-tooltip effect="dark" content="去编辑" placement="top">
                                            <i class="iconfont icon-qubianji" @click="goCustomRule(item.id)"
                                                v-show="item.name !== '成本控制策略'" />
                                        </el-tooltip>
                                    </el-row>
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="规则描述:" class="rule-form-item">
                            <div class="rule-description">
                                <span class="tip" v-if="!ruleForm.rule">选择自动规则后，展示规则详情说明</span>
                                <div v-else>
                                    <div class="rule-description-item">
                                        <span style="width: 100%;">
                                            <div class="name">
                                                <span>
                                                    {{ options.rule.length > 0 && options.rule.filter(i => i.id ===
                                                        ruleForm.rule)[0].name +
                                                        "：" }}
                                                </span>
                                                <i class="iconfont icon-qubianji" @click="goCustomRule(ruleForm.rule)"
                                                    v-show="options.rule.length > 0 && options.rule.filter(i => i.id === ruleForm.rule)[0].name !== '成本控制策略'" />
                                            </div>
                                            <div v-html="options.rule.length > 0 && options.rule.filter(i => i.id ===
                                                ruleForm.rule)[0].description" class="content"></div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="divider" v-if="ruleForm.type === 1 || ruleForm.type === 3" style="margin-top: 16px;">
                        广告组
                        <span @click="resetAudience" v-show="audienceSavable">恢复默认</span>
                    </div>
                    <el-form-item label="广告组名称:" prop="adsets_name" v-show="ruleForm.type === 1 || ruleForm.type === 3"
                        style="margin-bottom: 8px;">
                        <el-input size="small" clearable v-model="ruleForm.adsets_name" :validate-event="false" />
                    </el-form-item>
                    <el-form-item label="转化发生位置:" style="margin-bottom: 0px;">
                        <span>{{ ruleForm.optimization_goal }}</span>
                    </el-form-item>
                    <el-form-item label="成效目标:" style="margin-bottom: 0px;">
                        <span>{{ ruleForm.bid_strategy }}</span>
                    </el-form-item>
                    <el-form-item label="转化事件:" style="margin-bottom: 8px;">
                        <span>{{ ruleForm.custom_event_type }}</span>
                    </el-form-item>
                    <el-form-item label="单日预算:" prop="daily_budget" style="margin-bottom: 12px;">
                        <el-input size="small" clearable v-model="ruleForm.daily_budget"
                            style="width: 250px;margin-top: 4px;">
                            <template #append>$</template>
                        </el-input>
                        <span class="recommend"
                            v-show="ruleForm.daily_budget && (ruleForm.daily_budget < ruleForm.recommend_daily_budget)">推荐使用{{
                                ruleForm.recommend_daily_budget }}美元以上的预算，提高广告成功率</span>
                    </el-form-item>
                    <el-form-item label="投放开始时间:" style="margin-bottom: 8px;">
                        <span>{{ ruleForm.start_time }}</span>
                    </el-form-item>
                    <el-form-item label="性别:" v-if="ruleForm.type === 1" style="margin-bottom: 12px;">
                        <el-radio-group v-model="ruleForm.sex" size="small" text-color="#2B82FF" fill="#2B82FF">
                            <el-radio-button :label="0">全部</el-radio-button>
                            <el-radio-button :label="1">男</el-radio-button>
                            <el-radio-button :label="2">女</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="年龄:" v-if="ruleForm.type === 1" style="margin-bottom: 12px;">
                        <el-select v-model="ruleForm.age_min" placeholder="年龄" size="small" style="width: 90px;">
                            <el-option v-for="item in options.age_min" :key="item" :label="item" :value="item" />
                        </el-select>
                        <span class="age-divider" />
                        <el-select v-model="ruleForm.age_max" placeholder="年龄" size="small" style="width: 90px;">
                            <el-option v-for="item in options.age_max" :key="item" :label="item" :value="item" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="地区:" prop="region">
                        <el-select v-model="ruleForm.region" multiple placeholder="请选择地区" size="small"
                            style="width: 100%;" filterable>
                            <el-option v-for="item in options.region" :key="item.id" :label="item.name"
                                :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="受众兴趣词:" v-if="ruleForm.type === 1" prop="audience_interest_words"
                        style="margin-bottom: 24px;">
                        <el-select v-model="ruleForm.audience_interest_words" multiple placeholder="请选择受众兴趣词"
                            size="small" style="width: 100%;" filterable>
                            <el-option v-for="item in options.audience_interest_words" :key="item.id" :label="item.name"
                                :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="受众兴趣词:" v-if="ruleForm.type === 3" style="margin-bottom: 24px;">
                        <span>使用进阶赋能型受众，自动为你寻找最适合的广告受众</span>
                    </el-form-item>
                    <div class="divider">广告创意</div>
                    <material-tabs ref="materialTabsRef" @change="(index) => (currentTabIndex = index)"
                        @delete="deleteMaterial" @add="addMaterial" style="margin-left: 38px" :list="ruleForm.material"
                        baseTitle="广告">

                        <template #default>
                            <div v-for="(item, index) in ruleForm.material" :key="index">
                                <div v-show="currentTabIndex === index">
                                    <el-form-item label="广告样式:" label-width="80px" required style="margin-bottom: 0">
                                        <el-radio-group v-model="item.type" class="ml-4">
                                            <el-radio :label="1" size="small">单图片</el-radio>
                                            <el-radio :label="2" size="small">单视频</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="广告素材:" label-width="80px" required>
                                        <div class="material-container">
                                            <div class="left-material">
                                                <el-upload :action="uploadUrl" list-type="picture-card"
                                                    class="material-img-upload" :show-file-list="item.noImgUpload"
                                                    :on-progress="uploadProgress" :on-success="uploadSuccess"
                                                    :before-upload="beforeImgUpload" ref="imgUploadRef"
                                                    v-show="item.type === 1" v-loading="item.imgLoading"
                                                    element-loading-text="上传中，请耐心等待..."
                                                    :file-list="item.imgPath1 ? [{ url: item.imgPath1 }] : undefined">
                                                    <div class="upload-button">上传图片</div>
                                                    <div class="upload-tip">
                                                        <span>推荐长宽比: 1:1</span>
                                                        <span>推荐尺寸: 1080 * 1080像素</span>
                                                        <span>最小尺寸: 600 * 600像素</span>
                                                        <span>最大文件大小: 30MB</span>
                                                    </div>
                                                    <template #file="{ file }">
                                                        <div>
                                                            <img :src="file.url" alt="" />
                                                            <span class="el-upload-list__item-actions">
                                                                <span class="el-upload-list__item-delete"
                                                                    @click="handleRemove(file)">
                                                                    <el-icon>
                                                                        <Delete />
                                                                    </el-icon>
                                                                </span>
                                                                <div class="over-upload-tip">
                                                                    <span>推荐长宽比: 1:1</span>
                                                                    <span>推荐尺寸: 1080 * 1080像素</span>
                                                                    <span>最小尺寸: 600 * 600像素</span>
                                                                    <span>最大文件大小: 30MB</span>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </template>
                                                </el-upload>
                                                <el-upload :action="uploadUrl" list-type="picture-card"
                                                    class="material-img-upload" :show-file-list="item.noVideoUpload"
                                                    :on-progress="uploadVideoProgress" :on-success="uploadVideoSuccess"
                                                    :before-upload="beforeVideoUpload" ref="imgUploadRef"
                                                    v-show="item.type === 2" v-loading="item.videoLoading"
                                                    element-loading-text="上传中，请耐心等待..."
                                                    :file-list="item.videoPath1 ? [{ url: item.videoPath1 }] : undefined">
                                                    <div class="upload-button">上传视频</div>
                                                    <div class="upload-tip">
                                                        <span style="width: 165px">建议时长: 最长15秒</span>
                                                        <span style="width: 165px">建议格式: .mp4、.mov 或 .gif</span>
                                                        <span style="width: 165px">分辨率: 纵向 (4:5)</span>
                                                        <span style="width: 165px">推荐宽高比: 最小宽度为600px</span>
                                                        <span style="width: 165px">文件大小: 不超过50M</span>
                                                    </div>

                                                    <template #file="{ file }">
                                                        <div>
                                                            <video :src="file.url" alt="" />
                                                            <span class="el-upload-list__item-actions">
                                                                <span class="el-upload-list__item-delete"
                                                                    @click="handleVideoRemove(file)">
                                                                    <el-icon>
                                                                        <Delete />
                                                                    </el-icon>
                                                                </span>
                                                                <div class="over-upload-tip">
                                                                    <span style="width: 165px">建议时长: 最长15秒</span>
                                                                    <span style="width: 165px">建议格式: .mp4、.mov 或
                                                                        .gif</span>
                                                                    <span style="width: 165px">分辨率: 纵向 (4:5)</span>
                                                                    <span style="width: 165px">推荐宽高比: 最小宽度为600px</span>
                                                                    <span style="width: 165px">文件大小: 不超过50M</span>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </template>
                                                </el-upload>
                                            </div>
                                            <div class="right-material">
                                                <el-form-item :prop="`material[${index}].title`"
                                                    :rules="{ required: true, message: '请输入标题', trigger: 'change' }">
                                                    <el-input size="small" clearable v-model="item.title"
                                                        placeholder="请输入标题" :validate-event="false" />
                                                </el-form-item>
                                                <el-form-item :prop="`material[${index}].description`"
                                                    :rules="{ required: true, message: '请输入描述', trigger: 'change' }"
                                                    v-if="item.type === 1">
                                                    <el-input size="small" clearable v-model="item.description"
                                                        placeholder="描述:添加其他详情" :validate-event="false" />
                                                </el-form-item>
                                                <el-form-item :prop="`material[${index}].message`"
                                                    :rules="{ required: true, message: '请输入正文', trigger: 'change' }">
                                                    <el-input v-model="item.message" placeholder="正文:介绍广告内容"
                                                        type="textarea" :validate-event="false" />
                                                </el-form-item>
                                            </div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item required label-width="80px" style="padding-right: 30px"
                                        class="call-to-action">
                                        <template #label>
                                            <span>行动号召</span>
                                            <el-tooltip class="item" effect="light" content="" placement="top">
                                                <template #content>
                                                    <span>在广告中展示按钮或链接，吸引受众采取预期操作。</span>
                                                </template>
                                                <i class="el-icon-warning-outline"></i>
                                            </el-tooltip>
                                        </template>
                                        <el-select v-model="item.callType" placeholder="请选择行动号召" size="small"
                                            style="width: 100%;">
                                            <el-option v-for="item in options.call_type" :key="item.id"
                                                :label="item.name" :value="item.id" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="推广链接:" :prop="`material[${index}].link`" :rules="[
                                        { required: true, message: '请输入带http或者https推广链接' },
                                        { validator: validator.urlValidRule },
                                    ]" label-width="80px" style="padding-right: 30px">
                                        <el-input size="small" clearable v-model="item.link" placeholder="请输入推广链接"
                                            @paste.native="handlePaste" :validate-event="false" />
                                    </el-form-item>
                                </div>
                            </div>
                        </template>
                    </material-tabs>
                </el-form>
                <div v-show="currentStep === 1" style="padding-bottom: 40px;">
                    <div class="divider">投放预览</div>
                    <div class="preview-container">
                        <div class="series-name" style="margin-bottom: 16px;">
                            <span
                                style="font-weight: 600; font-family: PingFang SC-Medium; margin-right: 32px;">广告投放账号:</span>
                            {{ ruleForm.account_id }}
                        </div>
                        <div class="series-name">
                            <span
                                style="font-weight: 600; font-family: PingFang SC-Medium; margin-right: 32px">广告系列名称:</span>
                            {{ ruleForm.campaign_name }}
                        </div>
                        <div class="header">
                            <div>广告组名称</div>
                            <div>受众人群</div>
                            <div>广告组日预算</div>
                        </div>
                        <div class="content">
                            <div class="content-tree">
                                <el-tree :data="previewData" :props="{
                                    children: 'children',
                                    label: 'label',
                                }">
                                    <template #default="{ node, data }">
                                        <div v-if="data.path && node.level === 2">
                                            <img :src="data.path" style="width: 28px; height: 28px; margin-right: 4px"
                                                v-if="data.type === 1" />
                                            <video :src="data.path" style="width: 28px; height: 28px; margin-right: 4px"
                                                v-else></video>
                                        </div>
                                        <span>{{ node.label }}</span>
                                    </template>
                                </el-tree>
                            </div>
                            <div>
                                <span>{{ audience }}</span>
                            </div>
                            <div>
                                <span> $ {{ ruleForm.daily_budget }} </span>
                            </div>
                        </div>
                        <div class="footer">
                            <div>共生成{{ ruleForm.material.length }}条广告</div>
                            <div></div>
                            <div>每日总预算：${{ ruleForm.daily_budget }}</div>
                        </div>
                        <div class="rule-container" v-if="ruleForm.rule_switch && ruleForm.rule">
                            <span
                                style="font-weight: 600; font-family: PingFang SC-Medium; margin-right: 32px;">应用自动规则:</span>
                            <div class="rule-description">
                                <div class="rule-description-item">
                                    <span style="width: 100%;">
                                        <span>
                                            {{ options.rule.length > 0 && options.rule.filter(i => i.id ===
                                                ruleForm.rule)[0].name + "：" }}
                                        </span>
                                        <div v-html="options.rule.length > 0 && options.rule.filter(i => i.id ===
                                            ruleForm.rule)[0].description" class="description"></div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="divider">创建提示</div>
                    <span class="create-tip">广告模板创建成功后即开始投放，如果您有需要自定义的修改内容，可前往Meta广告管理后台进行修改。</span>
                </div>
            </div>
            <div class="detail-footer">
                <el-button @click="isSaveTemplateDialogShow = true" size="small" type="primary"
                    v-show="audienceSavable">保存为我的模板</el-button>
                <el-button @click="isDrawerShow = false" size="small" v-if="currentStep === 0">取消</el-button>
                <el-button @click="currentStep--" size="small" type="primary" v-else>上一步</el-button>
                <el-button @click="handleNext" size="small" v-if="currentStep === 0">下一步</el-button>
                <el-button @click="handleCreate" size="small" type="primary" v-else>创建广告</el-button>
            </div>
        </div>
    </el-drawer>

    <!-- FB授权弹窗 -->
    <LoginDialog ref="loginDialogRef" v-if="isDialogShow" @close="isDialogShow = false; isDrawerShow = false"
        @submit="handleFBAuth" />

    <!-- 我的模板保存弹窗 -->
    <SaveTemplateDialog v-if="isSaveTemplateDialogShow" @close="isSaveTemplateDialogShow = false"
        @save="$emit('save')" />

    <!-- 自定义规则弹窗 -->
    <RuleDrawer ref="ruleDrawerRef" v-if="isRuleDrawerShow" @submit="handleRuleChange"
        @close="isRuleDrawerShow = false" />
</template>

<script setup>
import LoginDialog from './LoginDialog.vue'
import MaterialTabs from './MaterialTabs.vue'
import SaveTemplateDialog from './SaveTemplateDialog.vue'
import RuleDrawer from '@/views/ads/adRule/RuleDrawer.vue'
import { ref, reactive, onMounted, nextTick, watch } from 'vue'
import { Delete } from '@element-plus/icons-vue'
import base from '@/request/base'
import api from '@/request/api/ad'
import { ElMessage } from 'element-plus'
import validator from '@/base/validator'
import filters from '@/base/filters/base'

const props = defineProps({
    id: { type: Number, default: 1 },
    name: { type: String, default: '' },
    audience: { type: String, default: '' },
})

const emit = defineEmits(['submit', 'close', 'save'])

const loginDialogRef = ref(null)
const drawerRef = ref(null)
const materialTabsRef = ref(null)
const imgUploadRef = ref(null)
const ruleDrawerRef = ref(null)
const isDrawerShow = ref(true)
const isRuleDrawerShow = ref(false) // 是否显示自定义规则弹窗
const isDialogShow = ref(false) // 是否显示授权弹窗
const isSaveTemplateDialogShow = ref(false) // 是否显示保存我的模板弹窗
const currentStep = ref(0)
const currentTabIndex = ref(0)
const uploadUrl = ref(`${base.admin_url}/cloud/file/uploadfile_creativity`)
const ruleFormRef = ref()
const ruleForm = ref({
    id: props.id, // 模板id
    type: 1, // 模板类型 1: FB正常 2: ASC模板
    c_id: '', // 模板分类id
    account_id: '', // FB广告账号id
    fb_user_id: '', // 授权FB用户id
    fb_avatar: '', // 授权FB头像
    fb_name: '', // 授权FB名称
    page_id: '', // 公共主页id
    pixel_id: '', // 像素id
    campaign_name: '', // 广告系列名称
    smart_promotion_type: '', // 广告目标
    ad_budget_improve: '关闭', // 赋能型广告预算优化
    rule_switch: false, // 自动规则开关
    rule: '', // 自动化规则
    adsets_name: '', // 广告组名称
    optimization_goal: '', // 转化发生位置
    bid_strategy: '', // 成效目标
    custom_event_type: '', // 转化事件
    daily_budget: null, // 单日预算
    recommend_daily_budget: 1, // 推荐最小预算
    start_time: '模板创建时间的第二天凌晨0点', // 投放开始时间
    region: '', // 地区
    sex: 0, // 性别
    age_min: '', // 最小年龄
    age_max: '', // 最大年龄
    audience_interest_words: '', // 受众兴趣词
    material: [], // 广告素材
})
const options = ref({
    page: [],
    pixel: [],
    rule: [],
    region: [],
    age_max: [],
    age_min: [],
    audience_interest_words: [],
    call_type: [],
})
const validateBudget = (rule, value, callback) => {
    if (parseFloat(value) < 1) {
        callback(new Error('预算金额最小1美金'))
    } else {
        callback()
    }
}
// 表单验证规则
const rules = reactive({
    page_id: [{ required: true, message: '请选择公共主页', trigger: 'change' }],
    pixel_id: [{ required: true, message: '请选择像素', trigger: 'change' }],
    daily_budget: [
        { required: true, message: '请输入单日预算', trigger: 'change' },
        { pattern: /^[0-9]+$/, message: '预算金额必须为数字', trigger: 'change' },
        { validator: validateBudget, trigger: 'change' },
    ],
    campaign_name: [{ required: true, message: '请输入广告系列名称' }],
    adsets_name: [{ required: true, message: '请输入广告组名称' }],
    region: [{ required: true, message: '请选择地区', trigger: 'change' }],
    audience_interest_words: [{ required: true, message: '请选择受众兴趣词', trigger: 'change' }],
})
const previewData = ref([])
const defaultAudience = ref({}) // 默认受众
const audienceSavable = ref(false) // 是否可保存我的模板



/** 检查用户授权 */
const checkAuth = () => {
    return new Promise((resolve, reject) => {
        if (localStorage.getItem('CurrentFBAuthUser')) {
            const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
            api.template.getFBAuthExpire(user.fb_user_id).then((res) => {
                if (res.code === 200) {
                    ruleForm.value.fb_user_id = user.fb_user_id
                    ruleForm.value.fb_avatar = user.fb_avatar
                    ruleForm.value.fb_name = user.fb_name
                    if (localStorage.getItem('AdTemplateAccountId')) ruleForm.value.account_id = localStorage.getItem('AdTemplateAccountId')
                    if (ruleForm.value.account_id) getPageAndPixel()
                }
                resolve()
            }).catch((error) => {
                resolve()
            })
        } else {
            resolve()
        }
    })
}

/** 检查当前模板是否有草稿 */
const checkDetailSaved = () => {
    nextTick(() => {
        if (localStorage.getItem('AdTemplateDetail')) {
            const detail = JSON.parse(localStorage.getItem('AdTemplateDetail'))
            if (ruleForm.value.id !== detail.id) return
            ruleForm.value.account_id = detail.account_id
            ruleForm.value.page_id = detail.page_id
            ruleForm.value.pixel_id = detail.pixel_id
            ruleForm.value.campaign_name = detail.campaign_name
            ruleForm.value.rule_switch = detail.rule_switch
            ruleForm.value.rule = detail.rule
            ruleForm.value.adsets_name = detail.adsets_name
            ruleForm.value.daily_budget = detail.daily_budget
            ruleForm.value.sex = detail.sex
            ruleForm.value.age_min = detail.age_min
            ruleForm.value.age_max = detail.age_max
            ruleForm.value.region = detail.region
            ruleForm.value.audience_interest_words = detail.audience_interest_words
            ruleForm.value.material = detail.material
        }
    })
}

/** 本地保存表单和受众 */
const handleSaveDetail = () => {
    localStorage.setItem('AdTemplateDetail', JSON.stringify(ruleForm.value))
    localStorage.setItem('AdTemplateCustomAudience', JSON.stringify(ruleForm.value))
    localStorage.setItem('AdTemplateAudience', props.audience)
    // 判断是否可保存我的模板
    if (ruleForm.value.sex !== defaultAudience.value.sex ||
        ruleForm.value.age_min !== defaultAudience.value.age_min ||
        ruleForm.value.age_max !== defaultAudience.value.age_max ||
        JSON.stringify(ruleForm.value.region) !== JSON.stringify(defaultAudience.value.region) ||
        JSON.stringify(ruleForm.value.audience_interest_words) != JSON.stringify(defaultAudience.value.audience_interest_words)) {
        audienceSavable.value = true
    } else {
        audienceSavable.value = false
    }
}

/** 打开授权弹窗 */
const openLoginDialog = () => {
    isDialogShow.value = true
    drawerRef.value.$el.nextElementSibling.style.display = 'none'
}

/** 选择广告账户 */
const changeAccount = () => {
    openLoginDialog()
    nextTick(() => {
        loginDialogRef.value.changeAccount(ruleForm.value.account_id)
    })
}

/** 清空规则 */
const clearRule = () => {
    if (!ruleForm.value.rule_switch) {
        ruleForm.value.rule = ''
    }
}

/** 跳转新建规则和编辑规则 */
const goCustomRule = async (id) => {
    if (!ruleForm.value.fb_user_id) {
        ElMessage.error('请先授权Facebook用户')
        return
    }
    isRuleDrawerShow.value = true
    await nextTick()
    if (id) {
        ruleDrawerRef.value.editRule(id)
    } else {
        ruleDrawerRef.value.addRule()
    }
}

/** 规则提交的回调 */
const handleRuleChange = (id) => {
    ruleForm.value.rule = id
    isRuleDrawerShow.value = false
    getAllAudience()
}

/**
 * 删除素材
 * @param {Number} index tab索引
 */
const deleteMaterial = (index) => {
    ruleForm.value.material.splice(index, 1)
    if (currentTabIndex.value !== 0 && currentTabIndex.value >= index) currentTabIndex.value -= 1
}

/** 添加素材 */
const addMaterial = () => {
    ruleForm.value.material.push({
        imgId: '',
        videoId: '',
        type: 1,
        imgPath: '', // 用来上传
        imgPath1: '', // 用来预览
        videoPath: '', // 用来上传
        videoPath1: '', // 用来预览
        title: '',
        description: '',
        message: '',
        callType: 'SHOP_NOW',
        link: 'https://',
        noImgUpload: false,
        noVideoUpload: false,
        imgLoading: false,
        videoLoading: false,
    })
}

/**
 * 素材图片上传前置钩子
 * @param {*} file
 */
const beforeImgUpload = (file) => {
    const isLt30M = file.size / 1024 / 1024 < 30
    if (!isLt30M) {
        ElMessage.error('上传图片大小不能超过 30MB!')
    }
    const isLtSize = new Promise(function (resolve, reject) {
        const img = new Image()
        const _URL = window.URL || window.webkitURL
        img.onload = function () {
            const valid = img.width >= 600 && img.height >= 600
            valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
    }).then(
        () => {
            return file
        },
        () => {
            ElMessage.error('上传素材图片尺寸不合符，最小尺寸: 600 * 600像素')
            return Promise.reject()
        }
    )

    return isLt30M && isLtSize
}

/**
 * 素材视频上传前置钩子
 * @param {*} file
 */
const beforeVideoUpload = (file) => {
    console.log(file)
    // const isLtType = file.type === 'video/mp4' || file.type === 'video/mov' || file.type === 'image/gif'
    // if (!isLtType) {
    //     ElMessage.error('上传视频格式只能为 .mp4、.mov 或 .gif!')
    // }
    const isLt50M = file.size / 1024 / 1024 < 50
    if (!isLt50M) {
        ElMessage.error('上传视频大小不能超过 50MB!')
    }
    const isLtSize = new Promise(function (resolve, reject) {
        const _URL = window.URL || window.webkitURL
        const audio = document.createElement('video')
        audio.addEventListener('loadedmetadata', function (_event) {
            const valid = audio.videoWidth >= 600 && audio.videoHeight >= 600
            valid ? resolve() : reject()
        })
        audio.src = _URL.createObjectURL(file)
    }).then(
        () => {
            return file
        },
        () => {
            ElMessage.error('上传素材视频宽度、高度不能小于600px!')
            return Promise.reject()
        }
    )
    return isLt50M && isLtSize
}

/**
 * 素材图片删除
 * @param {*} file
 */
const handleRemove = (file) => {
    console.log(file)
    ruleForm.value.material[currentTabIndex.value].imgId = ''
    ruleForm.value.material[currentTabIndex.value].noImgUpload = false
    ruleForm.value.material[currentTabIndex.value].imgPath = ''
    ruleForm.value.material[currentTabIndex.value].imgPath1 = ''
    imgUploadRef.value[currentTabIndex.value].clearFiles()
}

/**
 * 素材图片上传
 * @param {Object} response
 */
const uploadSuccess = (response, uploadFile) => {
    console.log('图片上传完成', response, uploadFile);
    if (response.code === '200') {
        ruleForm.value.material.forEach((item, index) => {
            if (item.imgId === uploadFile.uid) {
                ruleForm.value.material[index].imgLoading = false
                ruleForm.value.material[index].imgPath = uploadFile.response.path
                ruleForm.value.material[index].imgPath1 = uploadFile.response.path1
                ruleForm.value.material[index].noImgUpload = true
            }
        })
    } else {
        ruleForm.value.material.forEach((item, index) => {
            if (item.imgId === uploadFile.uid) {
                ruleForm.value.material[index].imgLoading = false
            }
        })
        ElMessage.error(response.msg)
    }
}

/**
 * 素材图片上传过程
 */
const uploadProgress = (evt, uploadFile, uploadFiles) => {
    if (uploadFile.status === 'ready') {
        ruleForm.value.material[currentTabIndex.value].imgId = uploadFile.uid
        ruleForm.value.material[currentTabIndex.value].imgLoading = true
    }
}

/**
 * 素材视频删除
 * @param {*} file
 */
const handleVideoRemove = (file) => {
    console.log(file)
    ruleForm.value.material[currentTabIndex.value].videoId = ''
    ruleForm.value.material[currentTabIndex.value].noVideoUpload = false
    ruleForm.value.material[currentTabIndex.value].videoPath = ''
    ruleForm.value.material[currentTabIndex.value].videoPath1 = ''
    imgUploadRef.value[currentTabIndex.value].clearFiles()
}

/**
 * 素材视频上传过程
 */
const uploadVideoProgress = (evt, uploadFile, uploadFiles) => {
    if (uploadFile.status === 'ready') {
        ruleForm.value.material[currentTabIndex.value].videoId = uploadFile.uid
        ruleForm.value.material[currentTabIndex.value].videoLoading = true
    }
}

/**
 * 素材视频上传
 * @param {Object} response
 */
const uploadVideoSuccess = (response, uploadFile) => {
    console.log('视频上传完成', response, uploadFile)
    if (response.code === '200') {
        ruleForm.value.material.forEach((item, index) => {
            if (item.videoId === uploadFile.uid) {
                ruleForm.value.material[index].videoLoading = false
                ruleForm.value.material[index].videoPath = uploadFile.response.path
                ruleForm.value.material[index].videoPath1 = uploadFile.response.path1
                ruleForm.value.material[index].noVideoUpload = true
            }
        })
    } else {
        ruleForm.value.material.forEach((item, index) => {
            if (item.videoId === uploadFile.uid) {
                ruleForm.value.material[index].videoLoading = false
            }
        })
        ElMessage.error(response.msg)
    }
}

/** 获取广告模板详情 */
const getTemplateDetail = async () => {
    await api.template.getTemplateDetail(props.id).then((res) => {
        ruleForm.value.type = res.data.type // 1: FB正常 2: ASC模板
        ruleForm.value.c_id = res.data.c_id
        ruleForm.value.smart_promotion_type = res.data.smart_promotion_type
        ruleForm.value.optimization_goal = res.data.optimization_goal
        ruleForm.value.bid_strategy = res.data.bid_strategy
        ruleForm.value.custom_event_type = res.data.custom_event_type
        ruleForm.value.sex = res.data.sex
        ruleForm.value.region = res.data.region
        ruleForm.value.age_min = res.data.age_min
        ruleForm.value.age_max = res.data.age_max
        ruleForm.value.audience_interest_words = res.data.audience_interest_words
        ruleForm.value.recommend_daily_budget = res.data.daily_budget
        handleDefaultName()
        // 如果用户已经授权并选择账户并且没有过期（赋值我的模板中存的授权信息）
        if (ruleForm.value.fb_user_id && res.data.c_id === 6) {
            ruleForm.value.account_id = res.data.account_info[0].account_id
            ruleForm.value.page_id = res.data.account_info[0].page_id
            ruleForm.value.pixel_id = res.data.account_info[0].pixel_id
        }
        defaultAudience.value = {
            sex: res.data.sex,
            age_min: res.data.age_min,
            age_max: res.data.age_max,
            region: res.data.region,
            audience_interest_words: res.data.audience_interest_words,
        }
    })
    checkDetailSaved()
}

/** 获取广告模板所有受众选项 */
const getAllAudience = () => {
    api.template.getAllAudience(props.id).then((res) => {
        options.value.rule = res.rule
        options.value.region = res.adv_area
        options.value.audience_interest_words = res.adv_interests
        options.value.age_min = res.age_min
        options.value.age_max = res.age_max
        options.value.call_type = res.call_type
    })
}

/** 生成广告系列、广告组、广告名称默认值 */
const handleDefaultName = () => {
    const date = filters.formatDate('YYYYMMdd')
    ruleForm.value.campaign_name = `${date}-${props.name}-${ruleForm.value.bid_strategy}`
    ruleForm.value.adsets_name = `${date}-${props.name}-${ruleForm.value.region.join('/')}`
    ruleForm.value.material.forEach((item) => {
        item.title = `${date}-${props.name}`
    })
}

/** 恢复默认受众 */
const resetAudience = () => {
    ruleForm.value.sex = defaultAudience.value.sex
    ruleForm.value.age_min = defaultAudience.value.age_min
    ruleForm.value.age_max = defaultAudience.value.age_max
    ruleForm.value.region = defaultAudience.value.region
    ruleForm.value.audience_interest_words = defaultAudience.value.audience_interest_words
}

/** 更改FB授权状态 */
const handleFBAuth = (user) => {
    ruleForm.value.account_id = localStorage.getItem('AdTemplateAccountId')
    ruleForm.value.fb_user_id = user.fb_user_id
    ruleForm.value.fb_avatar = user.fb_avatar
    ruleForm.value.fb_name = user.fb_name
    ruleForm.value.page_id = ''
    ruleForm.value.pixel_id = ''
    drawerRef.value.$el.nextElementSibling.style.display = 'block'
    isDialogShow.value = false
    getPageAndPixel()
}

/** 获取FB广告账户公共主页和像素 */
const getPageAndPixel = () => {
    api.template.getPageAndPixel(ruleForm.value.account_id, ruleForm.value.fb_user_id).then((res) => {
        options.value.page = res.page
        options.value.pixel = res.pixel
    })
}

/** 推广链接处理逻辑 */
const handlePaste = (e) => {
    // 阻止默认粘贴行为（可选，取决于你是否需要立即显示原始数据）
    e.preventDefault()
    // 获取剪贴板中的文本数据
    const text = (e.originalEvent || e).clipboardData.getData('text/plain').trim()
    // 如果检测text有自带http://或者https://，就用text的，如果没有，就用我们默认的https://
    const replacedText = text.includes('http://') || text.includes('https://') ? text : 'https://' + text
    // 将转换后的数据赋值给 inputValue
    ruleForm.value.material[currentTabIndex.value].link = replacedText
}

/** 校验是否是网址 */
const isURL = (str) => {
    // 创建正则表达式模式
    const pattern = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/

    // 使用正则表达式模式进行匹配
    return pattern.test(str);
}

/** 下一步预览广告 */
const handleNext = async () => {
    if (!ruleForm.value.fb_user_id) {
        ElMessage.error('请先授权Facebook用户')
        return
    }
    if (!ruleForm.value.account_id) {
        ElMessage.error('请选择广告账户')
        return
    }
    if (!ruleForm.value.page_id) {
        ElMessage.error('请选择公共主页')
        return
    }
    if (!ruleForm.value.pixel_id) {
        ElMessage.error('请选择像素')
        return
    }
    if (!ruleForm.value.campaign_name) {
        ruleFormRef.value.validateField('campaign_name', (error) => {
            if (error) ruleFormRef.value.scrollToField('campaign_name')
        })
        return
    }
    if (!ruleForm.value.adsets_name) {
        ruleFormRef.value.validateField('adsets_name', (error) => {
            if (error) ruleFormRef.value.scrollToField('adsets_name')
        })
        return
    }
    if (!ruleForm.value.daily_budget) {
        ruleFormRef.value.validateField('daily_budget', (error) => {
            if (error) ruleFormRef.value.scrollToField('daily_budget')
        })
        return
    }
    if (JSON.stringify(ruleForm.value.region) === JSON.stringify([])) {
        ruleFormRef.value.validateField('region', (error) => {
            if (error) ruleFormRef.value.scrollToField('region')
        })
        return
    }
    if (JSON.stringify(ruleForm.value.audience_interest_words) === JSON.stringify([])) {
        ruleFormRef.value.validateField('audience_interest_words', (error) => {
            if (error) ruleFormRef.value.scrollToField('audience_interest_words')
        })
        return
    }
    for (let [index, item] of ruleForm.value.material.entries()) {
        if (item.type === 1 && !item.imgPath) {
            ElMessage.error('请上传广告素材')
            materialTabsRef.value.onTabChange(index)
            return
        }
        if (item.type === 2 && !item.videoPath) {
            ElMessage.error('请上传广告素材')
            materialTabsRef.value.onTabChange(index)
            return
        }
    }
    await ruleFormRef.value.validate((valid, fields) => {
        if (!valid) {
            for (let [index, item] of ruleForm.value.material.entries()) {
                if (item.type === 1 && (!item.title || !item.description || !item.message || !item.link)) {
                    ElMessage.error(`广告${index + 1}有内容尚未填写，请检查。`)
                    materialTabsRef.value.onTabChange(index)
                    return
                }
                if (item.type === 2 && (!item.title || !item.message || !item.link)) {
                    ElMessage.error(`广告${index + 1}有内容尚未填写，请检查。`)
                    materialTabsRef.value.onTabChange(index)
                    return
                }
                if (!isURL(item.link)) {
                    ElMessage.error(`广告${index + 1}的推广链接不符合要求，请检查。`)
                    materialTabsRef.value.onTabChange(index)
                    return
                }
            }
            return
        }
        // 校验通过，生成预览广告
        previewData.value = [
            {
                id: 1,
                label: ruleForm.value.adsets_name,
                children: [],
            },
        ]
        ruleForm.value.material.forEach((item, index) => {
            previewData.value[0].children.push({
                id: index + 2,
                label: item.title,
                type: item.type,
                path: item.type === 1 ? item.imgPath1 : item.videoPath1,
            })
        })
        currentStep.value++
        api.template.handleEventTracking({ type: 13, source: localStorage.getItem('TemplateSource') })
    })
}

/** 创建广告 */
const handleCreate = () => {
    const material_data = ruleForm.value.material.map((item) => {
        return {
            type: item.type,
            path: item.type === 1 ? item.imgPath : item.videoPath,
            title: item.title,
            description: item.type === 1 ? item.description : undefined,
            message: item.message,
            call_type: item.callType,
            link: item.link,
        }
    })
    emit('submit', 'loading')
    api.template.createFBAd({
        user_id: ruleForm.value.fb_user_id,
        page_id: ruleForm.value.page_id,
        pixel_id: ruleForm.value.pixel_id,
        t_id: props.id,
        account_id: ruleForm.value.account_id,
        campaign_name: ruleForm.value.campaign_name,
        adsets_name: ruleForm.value.adsets_name,
        daily_budget: parseFloat(ruleForm.value.daily_budget),
        material_data: JSON.stringify(material_data),
        area: JSON.stringify(ruleForm.value.region),
        age_min: ruleForm.value.age_min + '',
        age_max: ruleForm.value.age_max + '',
        sex: ruleForm.value.sex,
        interests: JSON.stringify(ruleForm.value.audience_interest_words),
        rule_ids: ruleForm.value.rule_switch ? ruleForm.value.rule : ''
    }).then(res => {
        console.log(res);
        isDrawerShow.value = false
        localStorage.removeItem('AdTemplateDetail')
        emit('submit', 'success')
    }).catch(err => {
        console.log(err);
        isDrawerShow.value = false
        if (err.msg) {
            emit('submit', err.msg)
        } else {
            emit('submit', '网络错误')
        }
    })
}

onMounted(async () => {
    await checkAuth()
    getTemplateDetail()
    getAllAudience()
})

watch(
    [
        () => ruleForm.value.account_id,
        () => ruleForm.value.page_id,
        () => ruleForm.value.pixel_id,
        () => ruleForm.value.campaign_name,
        () => ruleForm.value.adsets_name,
        () => ruleForm.value.daily_budget,
        () => ruleForm.value.sex,
        () => ruleForm.value.age_min,
        () => ruleForm.value.age_max,
        () => ruleForm.value.rule_switch,
        () => ruleForm.value.rule
    ],
    (now, old) => {
        if (old[3] || old[7] || old[8]) {
            handleSaveDetail()
        }
    },
    { deep: true }
)

watch(() => ruleForm.value.region, (now, old) => {
    if (JSON.stringify(now) !== JSON.stringify([]) && JSON.stringify(old) !== JSON.stringify([])) {
        handleSaveDetail()
    }
}, { deep: true })

watch(() => ruleForm.value.audience_interest_words, (now, old) => {
    if (JSON.stringify(now) !== JSON.stringify([]) && JSON.stringify(old) !== JSON.stringify([])) {
        handleSaveDetail()
    }
}, { deep: true })

watch(
    () => ruleForm.value.material,
    (now, old) => {
        if (
            JSON.stringify(now) !==
            JSON.stringify([
                {
                    imgId: '',
                    videoId: '',
                    type: 1,
                    imgPath: '', // 用来上传
                    imgPath1: '', // 用来预览
                    videoPath: '', // 用来上传
                    videoPath1: '', // 用来预览
                    title: `${filters.formatDate('YYYYMMdd')}-${props.name}`,
                    description: '',
                    message: '',
                    callType: 'SHOP_NOW',
                    link: 'https://',
                    noImgUpload: false,
                    noVideoUpload: false,
                    imgLoading: false,
                    videoLoading: false,
                },
            ])
            && JSON.stringify(now) !==
            JSON.stringify([
                {
                    imgId: '',
                    videoId: '',
                    type: 1,
                    imgPath: '', // 用来上传
                    imgPath1: '', // 用来预览
                    videoPath: '', // 用来上传
                    videoPath1: '', // 用来预览
                    title: '',
                    description: '',
                    message: '',
                    callType: 'SHOP_NOW',
                    link: 'https://',
                    noImgUpload: false,
                    noVideoUpload: false,
                    imgLoading: false,
                    videoLoading: false,
                }
            ])
        ) {
            handleSaveDetail()
        }
    },
    { deep: true }
)
</script>

<style lang="less">
.template-detail-drawer {
    .el-drawer__header {
        height: 64px;
        padding: 0;
        margin: 0;
        display: block;
    }

    .el-drawer__body {
        padding: 0;
        margin: 0;
    }
}

.ad-template-rule-select {
    .add-rule {
        height: 34px;
        padding: 0 20px;
        color: #2b82ff;
        font-size: 14px;
        cursor: pointer;
        line-height: 34px;

        i {
            margin-right: 8px;
        }
    }

    .el-select-dropdown__item {
        height: 34px;
        display: flex;
        padding: 0 20px 0 46px;
        flex-direction: column;

        &::after {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            width: 16px;
            height: 16px;
            border: 1px solid #d9d9d9;
            border-radius: 50%;
            -webkit-font-smoothing: antialiased;
        }

        &.selected {
            font-weight: 400;
            background: #F5F7FA !important;
            border-radius: 0 !important;
        }

        &.selected::after {
            content: '' !important;
            background: #2B82FF;
            box-shadow: inset 0 0 0 3px #ffffff;
            border: 1px solid #2b82ff;
        }
    }
}
</style>

<style lang="less" scoped>
.top-container {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    background: #2b82ff;

    span {
        font-size: 16px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
    }

    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.detail-container {
    padding: 32px 0 16px 0;

    .detail-steps {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;

        .active-step {
            font-size: 16px;
            font-family: PingFang SC-Medium;
            font-weight: 500;
            color: #262626;
            line-height: 24px;
        }

        span {
            font-size: 16px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            color: #595959;
            line-height: 24px;
            margin-left: 8px;
        }

        div {
            width: 120px;
            height: 0px;
            opacity: 1;
            border: 1px solid #f0f0f0;
            margin: 0px 40px;
        }
    }

    .detail-form {
        height: calc(100vh - 224px);
        overflow: auto;
        padding: 0 20px 0 24px;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #d9d9d9;
            border-radius: 8px;
        }

        :deep(.el-input-group__append, .el-input-group__prepend) {
            padding: 0 12px;
            font-size: 14px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            color: #262626;
        }

        :deep(.call-to-action .el-form-item__label) {
            width: 90px !important;
            padding-right: 22px;
        }

        :deep(.el-form-item__label) {
            width: 150px;
            box-sizing: content-box;
            padding-right: 32px;
            font-family: PingFang SC-Regular;
            color: #262626 !important;
        }

        :deep(.el-form-item) {
            margin-bottom: 16px;
        }

        :deep(.login-button.el-button) {
            border: 1px solid #2b82ff;
            font-size: 14px;
            font-family: PingFang SC-Medium;
            font-weight: 500;
            color: #2b82ff;
        }

        :deep(.unlogin-button.el-button) {
            font-size: 14px;
            font-family: PingFang SC-Medium;
            font-weight: 500;
        }

        :deep(.el-input__inner) {
            color: #262626;
        }

        :deep(.el-radio-button__inner) {
            background-color: #ffffff !important;
            color: #262626;
        }

        :deep(.el-icon-close) {
            top: -1px;
            right: -5px;
        }

        :deep(.el-tag--mini) {
            height: 22px;
            line-height: 22px;
            padding: 0 8px;
        }

        :deep(.el-select__tags-text) {
            font-size: 14px;
            color: #262626;
        }

        .divider {
            position: relative;
            box-sizing: border-box;
            width: 100%;
            height: 34px;
            background: #f4f6f8;
            border-radius: 2px;
            font-size: 14px;
            font-family: PingFang SC-Medium;
            font-weight: 500;
            color: #262626;
            line-height: 34px;
            padding: 0 20px 0 28px;
            margin-bottom: 24px;
            display: flex;
            justify-content: space-between;

            span {
                cursor: pointer;
                color: #2b82ff;
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 16px;
                transform: translateY(-50%);
                display: inline-block;
                width: 3px;
                height: 14px;
                background: #2b82ff;
                border-radius: 4px;
            }
        }

        .age-divider {
            width: 14px;
            height: 1px;
            background-color: #595959;
            margin: 2px 8px;
            display: inline-block;
        }

        .login-user {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 16px;

            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }

            span {
                line-height: 16px;
                vertical-align: middle;
            }
        }

        .rule-form-item {
            :deep(.el-form-item__label) {
                line-height: normal;
            }

            .rule-description {
                width: 100%;
                height: 118px;
                background: #FFFFFF;
                border-radius: 4px;
                border: 1px solid #DCDFE6;
                overflow-y: auto;
                line-height: normal;
                padding: 6px 0;

                &::-webkit-scrollbar {
                    width: 2px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #d9d9d9;
                    border-radius: 8px;
                }

                .rule-description-item {
                    display: flex;
                    margin: 0 8px 12px 8px;

                    .name {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background: #F4F4F5;
                        border-radius: 4px;
                        border: 1px solid #E9E9EB;
                        padding: 0 8px;
                        height: 24px;
                        line-height: 24px;

                        span {
                            font-weight: 500;
                            color: #262626;
                            font-family: PingFang SC-Regular;
                            font-size: 14px;
                            text-align: justified;
                        }

                        i {
                            color: #8c8c8c;
                            cursor: pointer;
                        }
                    }

                    .content {
                        padding-left: 8px;
                        margin-top: 2px;
                        line-height: 1.5;
                    }
                }

                .tip {
                    font-family: PingFang SC-Regular;
                    font-weight: 400;
                    font-size: 14px;
                    color: #c0c4cc;
                    margin-left: 14px;
                }
            }
        }

        .help {
            font-size: 14px;
            font-family: PingFang SC-Regular;
            text-decoration: none;
            font-weight: 400;
            color: #2b82ff;
            margin-left: 16px;
            cursor: pointer;
        }

        .recommend {
            font-size: 12px;
            color: #ffb900;
            margin-left: 16px;
            margin-bottom: 8px;
        }

        .material-container {
            display: flex;
            align-items: center;
            gap: 26px;

            :deep(.el-upload-list__item) {
                transition: none !important;
                -webkit-transition: nonne !important;
            }

            :deep(.el-upload-list__item-name) {
                transition: none !important;
                -webkit-transition: nonne !important;
            }

            .left-material {
                width: 246px;

                .material-img-upload {
                    position: relative;
                    width: 246px;
                    height: 246px;
                }

                .material-img-upload :deep(.el-upload) {
                    position: relative;
                    width: 246px;
                    height: 246px;
                }

                :deep(.el-upload-list) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    z-index: 9;
                }

                :deep(.el-upload-list__item) {
                    width: 100%;
                    height: 100%;

                    &:hover {
                        .el-upload-list__item-actions {
                            opacity: 1;
                        }

                        span {
                            display: inline-block;
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                    video {
                        width: 246px;
                        height: 246px;
                    }

                    .el-upload-list__item-actions {
                        height: 50%;
                        bottom: 0;
                        top: auto;
                    }

                    .over-upload-tip {
                        position: absolute;
                        left: 0;
                        bottom: 16px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        span {
                            width: 150px;
                            text-align: left;
                            font-size: 12px;
                            font-family: PingFang SC-Regular;
                            font-weight: 300;
                            color: #ffffff;
                            line-height: 20px;
                            margin: 0;
                        }
                    }

                    .el-upload-list__item-delete {
                        position: absolute;
                        top: -95%;
                        right: 8px;

                        .el-icon {
                            width: 24px;
                            height: 24px;
                            background-color: rgba(0, 0, 0, 0.5);

                            svg {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }

                .upload-button {
                    position: absolute;
                    left: 50%;
                    top: 30%;
                    transform: translate(-50%, 0);
                    width: 88px;
                    height: 32px;
                    border-radius: 4px;
                    border: 1px solid #2b82ff;
                    font-size: 14px;
                    font-family: PingFang SC-Medium;
                    font-weight: 500;
                    color: #2b82ff;
                    text-align: center;
                    line-height: 32px;
                    cursor: pointer;
                }

                .upload-tip {
                    position: absolute;
                    left: 0;
                    bottom: 16px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span {
                        width: 150px;
                        text-align: left;
                        font-size: 12px;
                        font-family: PingFang SC-Regular;
                        font-weight: 400;
                        color: #8c8c8c;
                        line-height: 20px;
                    }
                }
            }

            .right-material {
                width: 278px;
                height: 246px;
                display: flex;
                flex-direction: column;

                :deep(.el-form-item:last-child) {
                    flex: 1;
                    margin-bottom: 0;
                }

                :deep(.el-textarea) {
                    height: 100% !important;
                }

                :deep(.el-textarea__inner) {
                    height: 100% !important;
                }
            }
        }

        .preview-container {
            padding-left: 58px;
            margin-bottom: 24px;

            .series-name {
                font-size: 14px;
                font-family: PingFang SC-Regular;
                font-weight: 400;
                color: #262626;
            }

            .header {
                width: 100%;
                height: 46px;
                background: #f7f8fa;
                display: flex;
                margin-top: 16px;

                div {
                    position: relative;
                    flex: 1;
                    padding-left: 40px;
                    font-size: 14px;
                    font-family: PingFang SC-Medium;
                    font-weight: 600;
                    color: #262626;
                    line-height: 46px;
                }

                &>div:nth-child(1)::after,
                &>div:nth-child(2)::after {
                    content: '';
                    position: absolute;
                    top: 25%;
                    right: 0;
                    height: 22px;
                    width: 0px;
                    border: 1px solid #e8e9eb;
                }
            }

            .content {
                display: flex;
                align-items: center;
                height: 100px;
                box-sizing: border-box;
                border-bottom: 1px solid #80868a;

                .content-tree {
                    max-height: 100%;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 4px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #d9d9d9;
                        border-radius: 8px;
                    }
                }

                span {
                    font-size: 14px;
                    font-family: PingFang SC-Regular;
                    font-weight: 400;
                    color: #262626;
                }

                &>div {
                    flex: 1;
                }

                &>div:not(:nth-child(1)) {
                    span {
                        padding-left: 40px;
                    }
                }

                :deep(.el-tree-node__expand-icon) {
                    color: #4f4f4f;
                    font-size: 14px;
                }

                :deep(.el-tree-node__expand-icon.is-leaf) {
                    color: transparent !important;
                }

                :deep(.el-tree-node__content) {
                    background: #ffffff !important;
                }
            }

            .footer {
                display: flex;
                width: 100%;
                height: 46px;
                background: #f7f8fa;
                border: 1px solid #e8e9eb;

                &>div {
                    flex: 1;
                    font-size: 14px;
                    font-family: PingFang SC-Regular;
                    font-weight: 400;
                    color: #262626;
                    line-height: 46px;
                    padding-left: 40px;
                }
            }

            .rule-container {
                font-size: 14px;
                font-family: PingFang SC-Regular;
                font-weight: 400;
                color: #262626;
                margin-top: 16px;
                display: flex;

                .rule-description {
                    flex: 1;
                    height: 118px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #DCDFE6;
                    overflow-y: auto;
                    line-height: normal;
                    padding: 8px 0;

                    &::-webkit-scrollbar {
                        width: 2px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #d9d9d9;
                        border-radius: 8px;
                    }

                    .rule-description-item {
                        display: flex;
                        margin: 0 8px 12px 12px;

                        span {
                            font-weight: 500;
                            color: #262626;
                            font-family: PingFang SC-Regular;
                            font-size: 14px;
                            text-align: justified;
                        }

                        .description {
                            font-weight: 400;
                            color: #595959;
                            margin-top: 2px;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }

        .create-tip {
            margin-left: 58px;
            font-size: 14px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            color: #262626;
        }
    }

    .detail-footer {
        box-sizing: border-box;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 800px;
        height: 64px;
        background: #ffffff;
        box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.08);
        display: flex;
        padding: 16px 24px;
        justify-content: flex-end;

        :deep(.el-button) {
            margin-left: 20px;
            font-family: PingFang SC-Medium;
            font-size: 14px;
        }

        :deep(.el-button.is-disabled) {
            color: #606266;
            border-color: #c6e2ff;
        }
    }


    :deep(.el-radio.el-radio--small) {
        height: 100%;

        .el-radio__label {
            padding-left: 8px;
        }
    }

    :deep(.vxe-switch) {
        padding: 0;

        .vxe-switch--button:focus {
            box-shadow: none !important;
        }
    }
}
</style>
